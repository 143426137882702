import 'animate.css';
import '../styles/styles.scss';

import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useEffect, useMemo } from 'react';

import Layout from '../components/layout';

import ChapterPrivacyModal from '../components/chapterPrivacyModal';
import Footer from '../components/footer';
import NavHeader from '../components/nav';
import {
  Accordion,
  CallToAction,
  Checklist,
  Header,
  HeaderHome,
  HighlightedText,
  Image,
  InternetAfhankelijkheid,
  InternetEnWaarden,
  LikertScaleQuestion,
  MatrixStrafbareFeiten,
  Modules,
  OnlineCognitionScale,
  OpenQuestion,
  Progress,
  RangeQuestion,
  TableQuestion,
  TableQuestionRowTitles,
  Text,
  TextHighlight,
  TextImage,
} from '../components/repeatables';
import SearchResultsWrapper from '../components/searchResults';
import SideButtons from '../components/sideButtons';
import TopNav from '../components/topnav';

import { navigate } from '@reach/router';
import { Toaster } from 'react-hot-toast';
import ChapterHeader from '../components/chapterHeader';
import ChaptersProgress from '../components/chaptersProgress';
import Seo from '../components/SEO';
import { MenuContext, TranslationsContext } from './contexts';
import { LocalStorageContext, useStorage } from './localStorageContext';

const CHAPTER_TEMPLATE = 'chapter.liquid';
const MODULE_TEMPLATE = 'module.liquid';

export const modulePageQuery = graphql`
  fragment ModulePage on NimbuPage {
    id
    nimbuId
    nimbuParent
    title
    seo_title
    position
    template
    fullpath
    items {
      description {
        content
      }
      image {
        file {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
      title {
        content
      }
      applicable_to {
        slug
        name
        nimbuId
      }
      applicable_for {
        slug
        name
        nimbuId
      }
      downloads {
        repeatables {
          items {
            file {
              file {
                localFile {
                  publicURL
                }
              }
            }
            name {
              content
            }
          }
        }
      }
    }
  }
`;

export const pageQuery = graphql`
  query($id: String!, $parentId: String!, $fullpathGlob: String!) {
    page: nimbuPage(id: { eq: $id }) {
      id
      template
      items {
        Blokken {
          repeatables {
            id
            slug
            items {
              Accent {
                content
              }
              Alignment {
                content
              }
              Background_Image {
                file {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
              Background_Overlay_Color {
                content
              }
              Background_Overlay_Opacity {
                content
              }
              Button_alt_Link {
                content
              }
              Button_alt_Text {
                content
              }
              Button_Link {
                content
              }
              Button_Text {
                content
              }
              Content {
                content
              }
              Examples {
                content
              }
              Highlight {
                content
              }
              Image {
                file {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
                    }
                  }
                }
              }
              Image_Alt {
                content
              }
              Image_Size {
                content
              }
              Items {
                repeatables {
                  id
                  items {
                    Name {
                      content
                    }
                    Description {
                      content
                    }
                    Link {
                      content
                    }
                  }
                }
              }
              LeftExtreme {
                content
              }
              Link {
                content
              }
              Options {
                content
              }
              Question {
                content
              }
              RightExtreme {
                content
              }
              Spacing_Options {
                content
              }
              TextWidth {
                content
              }
              Themas {
                repeatables {
                  id
                  items {
                    Thema {
                      content
                    }
                    Titel {
                      content
                    }
                    Inspanning {
                      content
                    }
                    Invloed {
                      content
                    }
                  }
                }
              }
              Theme {
                content
              }
              Title {
                content
              }
              Subtitle {
                content
              }
              Tooltip {
                content
              }
              UsersOnly {
                content
              }
              Vragen {
                repeatables {
                  id
                  items {
                    Content {
                      content
                    }
                    Topic {
                      content
                    }
                    Opties {
                      content
                    }
                    Vraag {
                      content
                    }
                  }
                }
              }
            }
          }
        }
        Menu {
          slug
          items {
            target_url
            name
            slug
          }
        }
        downloads {
          repeatables {
            items {
              file {
                file {
                  filename
                  url
                  localFile {
                    publicURL
                  }
                }
              }
              name {
                content
              }
            }
          }
        }
      }
    }
    siblings: allNimbuPage(
      sort: { fields: position, order: ASC }
      filter: { published: { eq: true }, nimbuParent: { eq: $parentId } }
    ) {
      edges {
        node {
          id
          nimbuId
          title
          seo_title
          template
          fullpath
        }
      }
    }
    allChildModules: allNimbuPage(
      sort: { fields: position, order: ASC }
      filter: {
        published: { eq: true }
        template: { eq: "module.liquid" }
        fullpath: { glob: $fullpathGlob }
      }
    ) {
      edges {
        node {
          ...ModulePage
        }
      }
    }
    parentModule: nimbuPage(nimbuId: { eq: $parentId }, template: { eq: "module.liquid" }) {
      ...ModulePage
    }
    allChildChapters: allNimbuPage(
      sort: { fields: position, order: ASC }
      filter: {
        published: { eq: true }
        template: { eq: "chapter.liquid" }
        fullpath: { glob: $fullpathGlob }
      }
    ) {
      edges {
        node {
          id
          nimbuId
          nimbuParent
          title
          seo_title
          position
          template
          fullpath
        }
      }
    }
    allTranslations: allNimbuTranslation {
      edges {
        node {
          key
          value
        }
      }
    }
    allPopups: allNimbuPopupsChannelEntry {
      edges {
        node {
          id
          nimbuId
          slug
          titel
          content
          cta_tekst_be
          cta_link_be
          cta_tekst_nl
          cta_link_nl
          zichtbaar_vanaf
          zichtbaar_tot
        }
      }
    }
  }
`;

const Repeatable = ({ repeatable }) => {
  switch (repeatable.slug) {
    case 'header': {
      const image = getImage(repeatable.items.Background_Image?.file?.localFile);

      return (
        <Header
          title={repeatable.items.Title.content}
          image={image}
          backgroundOverlay={repeatable.items.Background_Overlay_Color.content}
          backgroundOverlayOpacity={repeatable.items.Background_Overlay_Opacity.content}
          subtitle={repeatable.items.Subtitle?.content}
          link={repeatable.items.Link?.content}
          onlyForUsers={repeatable.items.UsersOnly?.content === 'true'}
        ></Header>
      );
    }
    case 'header_home': {
      const image = getImage(repeatable.items.Background_Image?.file?.localFile);

      return (
        <HeaderHome
          title={repeatable.items.Title.content}
          image={image}
          backgroundOverlay={repeatable.items.Background_Overlay_Color.content}
          backgroundOverlayOpacity={repeatable.items.Background_Overlay_Opacity.content}
        ></HeaderHome>
      );
    }
    case 'text': {
      return <Text content={repeatable.items.Content.content}></Text>;
    }
    case 'highlighted_text': {
      return <HighlightedText content={repeatable.items.Content.content}></HighlightedText>;
    }

    case 'text_with_highlight': {
      return (
        <TextHighlight
          content={repeatable.items.Content.content}
          highlight={repeatable.items.Highlight.content}
          textWidth={repeatable.items.TextWidth.content}
          accent={repeatable.items.Accent.content}
          // theme={repeatable.items.Theme.content}
          // spacing={repeatable.items.Spacing_Options.content}
        ></TextHighlight>
      );
    }
    case 'image': {
      const image = getImage(repeatable.items.Image?.file?.localFile);

      return <Image image={image} alt={repeatable.items.Image_Alt.content}></Image>;
    }
    case 'text_with_image': {
      const image = getImage(repeatable.items.Image?.file?.localFile);

      return (
        <TextImage
          content={repeatable.items.Content.content}
          alignment={repeatable.items.Alignment.content}
          imageSize={repeatable.items.Image_Size.content}
          image={image}
          alt={repeatable.items.Image_Alt.content}
          accent={repeatable.items.Accent.content}
          theme={repeatable.items.Theme.content}
          spacing={repeatable.items.Spacing_Options.content}
        ></TextImage>
      );
    }
    case 'call_to_action': {
      return (
        <CallToAction
          content={repeatable.items.Content.content}
          but_text={repeatable.items.Button_Text.content}
          but_link={repeatable.items.Button_Link.content}
          alt_text={repeatable.items.Button_alt_Text.content}
          alt_link={repeatable.items.Button_alt_Link.content}
          theme={repeatable.items.Theme.content}
          spacing={repeatable.items.Spacing_Options.content}
        ></CallToAction>
      );
    }
    case 'open_question': {
      return (
        <OpenQuestion
          id={repeatable.id}
          question={repeatable.items.Question.content}
          explanation={repeatable.items.Content.content}
          tooltip={repeatable.items.Tooltip.content}
        ></OpenQuestion>
      );
    }
    case 'range_question': {
      return (
        <RangeQuestion
          id={repeatable.id}
          question={repeatable.items.Question.content}
          explanation={repeatable.items.Content.content}
          tooltip={repeatable.items.Tooltip.content}
          leftExtreme={repeatable.items.LeftExtreme.content}
          rightExtreme={repeatable.items.RightExtreme.content}
          numeric={repeatable.items.Options.content}
        ></RangeQuestion>
      );
    }
    case 'accordion': {
      return (
        <Accordion
          title={repeatable.items.Title.content}
          content={repeatable.items.Content.content}
        ></Accordion>
      );
    }
    case 'likert_scale_question': {
      return (
        <LikertScaleQuestion
          id={repeatable.id}
          title={repeatable.items.Title.content}
          questions={repeatable.items.Question.content}
          explanation={repeatable.items.Content.content}
          tooltip={repeatable.items.Tooltip.content}
          options={repeatable.items.Options.content}
        ></LikertScaleQuestion>
      );
    }
    case 'table_question': {
      return (
        <TableQuestion
          id={repeatable.id}
          title={repeatable.items.Title.content}
          explanation={repeatable.items.Content.content}
          tooltip={repeatable.items.Tooltip.content}
          headers={repeatable.items.Question.content}
          examples={repeatable.items.Examples.content}
        ></TableQuestion>
      );
    }
    case 'internet_afhankelijkheid': {
      const vragen =
        repeatable.items.Vragen.repeatables.map((vraag) => ({
          content: vraag.items.Content.content,
          id: vraag.id,
        })) ?? [];

      return (
        <InternetAfhankelijkheid
          id={repeatable.id}
          content={repeatable.items.Content.content}
          questions={vragen}
        ></InternetAfhankelijkheid>
      );
    }
    case 'internet_en_waarden': {
      const themes = repeatable.items.Themas.repeatables.map((theme) => ({
        id: theme.id,
        title: theme.items.Titel.content,
        theme: theme.items.Thema.content,
        invloed: theme.items.Invloed.content,
        inspanning: theme.items.Inspanning.content,
      }));

      return (
        <InternetEnWaarden
          id={repeatable.id}
          content={repeatable.items.Content.content}
          themes={themes}
        ></InternetEnWaarden>
      );
    }
    case 'online_cognition_scale': {
      const questions = repeatable.items.Vragen.repeatables.map((question) => ({
        id: question.id,
        question: question.items.Content.content,
        topic: question.items.Topic.content,
      }));

      return (
        <OnlineCognitionScale
          id={repeatable.id}
          content={repeatable.items.Content.content}
          questions={questions}
        ></OnlineCognitionScale>
      );
    }
    case 'matrix_strafbare_feiten': {
      const questions = repeatable.items.Vragen.repeatables.map((question) => ({
        id: question.id,
        question: question.items.Vraag.content,
        options: question.items.Opties.content
          .split('\n')
          .map((option) => option.trim())
          .filter((option) => option !== ''),
      }));

      return (
        <MatrixStrafbareFeiten
          id={repeatable.id}
          content={repeatable.items.Content.content}
          questions={questions}
        ></MatrixStrafbareFeiten>
      );
    }
    case 'table_question_row_titles': {
      return (
        <TableQuestionRowTitles
          id={repeatable.id}
          title={repeatable.items.Title.content}
          explanation={repeatable.items.Content.content}
          tooltip={repeatable.items.Tooltip.content}
          columnTitles={repeatable.items.Question.content}
          rowTitles={repeatable.items.Examples.content}
          newRowButton={repeatable.items.Options}
        ></TableQuestionRowTitles>
      );
    }
    case 'blok_met_references': {
      const channel = repeatable.items.Reference_Channel_Entry.channel.slug;
      const entryName = repeatable.items.Reference_Channel_Entry.slug;
      const iframeSrc = `${channel}/${entryName}`;
      const title = `${channel}-${entryName}`;
      return <iframe src={iframeSrc} title={title}></iframe>;
    }
    case 'checklist': {
      const items = repeatable.items.Items.repeatables
        .map((item) => ({
          id: item.id,
          name: item.items.Name?.content,
          description: item.items.Description?.content,
          link: item.items.Link?.content,
        }))
        .filter((item) => item.name);

      return (
        <Checklist
          id={repeatable.id}
          title={repeatable.items.Title?.content}
          subtitle={repeatable.items.Subtitle?.content}
          items={items}
        />
      );
    }
    default:
      return '';
  }
};

const ModuleOverview = ({ modules }) => {
  return <Modules modules={modules} />;
};

const extractModulePageItems = (currentPage, chapterPages) => {
  const pageItems = currentPage.items;
  const id = currentPage.nimbuId;
  const nimbuId = currentPage.nimbuId;
  const title = pageItems.title.content;
  const description = pageItems.description.content;
  const image = pageItems.image?.file;
  const documents =
    pageItems.downloads?.repeatables.map((r) => ({
      url: r.items.file.file.localFile.publicURL,
      name: r.items.name.content,
    })) ?? [];

  const chapters = chapterPages.filter((e) => e.node.nimbuParent === id).map((e) => e.node);
  const applicableTo = pageItems.applicable_to?.map((a) => a.slug) ?? [];
  const applicableFor = pageItems.applicable_for?.map((a) => a.slug) ?? [];

  return {
    id,
    nimbuId,
    title,
    description,
    image,
    chapters,
    documents,
    applicableTo,
    applicableFor,
  };
};

const extractTranslations = (edges) => {
  let translations = {};
  for (const edge of edges) {
    const node = edge.node;
    translations[node.key] = node.value;
  }

  return translations;
};

const Page = ({ data, pageContext }) => {
  const storage = useStorage();
  const popups = data.allPopups.edges.map((e) => e.node);
  const isHomepage = pageContext.isHomepage;
  const repeatables = useMemo(() => data.page?.items?.Blokken?.repeatables ?? [], [data.page]);
  const template = data.page.template;
  const translations = useMemo(() => extractTranslations(data.allTranslations.edges), [
    data.allTranslations.edges,
  ]);

  const modulesForPage = useMemo(() => {
    return data.allChildModules.edges.map((e) =>
      extractModulePageItems(e.node, data.allChildChapters.edges)
    );
  }, [data.allChildModules, data.allChildChapters]);

  let content = repeatables.map((r, i) => {
    switch (r.slug) {
      case 'module_overview': {
        return <ModuleOverview key={i} repeatable={r} modules={modulesForPage} />;
      }
      case 'module_progress': {
        return <Progress key={i} content={r.items.Content.content} currentPage={pageContext} />;
      }
      default: {
        return <Repeatable key={i} repeatable={r} />;
      }
    }
  });

  useEffect(() => {
    if (
      template === MODULE_TEMPLATE &&
      repeatables.length === 0 &&
      data.allChildModules.edges.length > 0
    ) {
      // redirect to first chapter
      const firstChapter = data.allChildChapters.edges[0].node;
      navigate(firstChapter.fullpath + '/');
    }
  }, [template, repeatables, data.allChildModules.edges.length, data.allChildChapters.edges]);

  if (template === CHAPTER_TEMPLATE && data.parentModule != null) {
    const module = extractModulePageItems(data.parentModule, data.allChildChapters.edges);
    const modulesPath = pageContext.fullpath.split('/').slice(0, -3).join('/');
    const alternativeTitle = data.page.items.intro?.content;

    content = (
      <React.Fragment>
        <ChapterHeader module={module} alternativeTitle={alternativeTitle} />
        <ChaptersProgress
          allChapters={data.siblings.edges.map((e) => e.node)}
          currentChapter={pageContext.node}
          nextModule={pageContext.nextModule}
          modulesPath={modulesPath}
        />
        <ChapterPrivacyModal />
        <div className="layout-chapter">{content}</div>
        <ChaptersProgress
          allChapters={data.siblings.edges.map((e) => e.node)}
          currentChapter={pageContext.node}
          nextModule={pageContext.nextModule}
          modulesPath={modulesPath}
        />
      </React.Fragment>
    );
  }

  useEffect(() => {
    if (storage?.isLoggedIn && template === CHAPTER_TEMPLATE) {
      storage.markChapterAsRead(pageContext.nimbuId);
    }
  }, [template, storage, storage?.isLoggedIn, pageContext.nimbuId]);

  return (
    <LocalStorageContext.Provider value={storage}>
      <MenuContext.Provider value={data.page.items.Menu}>
        <TranslationsContext.Provider value={translations}>
          <Layout>
            <Seo
              node={pageContext.node}
              title={pageContext.title}
              pathname={pageContext.fullpath}
              desc={pageContext.description}
              article
            />
            <SideButtons />
            <TopNav />
            <NavHeader />
            <SearchResultsWrapper />
            {content}
            <Footer popups={popups} isHomepage={isHomepage} />
          </Layout>
          <Toaster />
        </TranslationsContext.Provider>
      </MenuContext.Provider>
    </LocalStorageContext.Provider>
  );
};

export default Page;
